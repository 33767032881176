<template>
  <li
    class="screen-li"
    @click="showPicker(data.dap)"
  >
    <div class="screen-title">
      {{ data.title }}
    </div>
    <div class="screen-value">
      <span class="screen-val">{{ data.name }}</span>
      <i class="icon-joyo arrows">&#xe66c;</i>
    </div>
    <van-popup
      v-model="showVantPicker"
      round
      position="bottom"
      :close-on-click-overlay="false"
      :get-container="container"
      @click.native.stop="() => {}"
      @click-overlay="clickOverlay"
    >
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showVantPicker = false"
        @confirm="onConfirm"
      >
        <template #title>
          <div>请选择</div>
        </template>
      </van-picker>
    </van-popup>
  </li>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    },
    pickType: {
      type: String,
      default: 'cube'
    }
  },
  data() {
    return {
      showVantPicker: false,
      container: 'body'
    }
  },
  computed: {
    columns() {
      let columns = []
      if (this.pickType === 'vant') {
        columns = this.data.options.map(option => option.text)
      }
      return columns
    }
  },
  methods: {
    showPicker(dap = true) {
      if (this.pickType === 'vant') {
        this.showVantPicker = true
        return
      }
      if (dap) {
        if (!this.picker) {
          this.picker = this.$createPicker({
            title: '请选择',
            data: [this.data.options],
            onSelect: this.selectHandle
          });
        }
        this.picker.show();
      } else {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit('toTree', this.data.typeName);
      }
    },
    selectHandle(selectedVal, selectedIndex, selectedText) {
      // eslint-disable-next-line vue/no-mutating-props
      this.data.name = selectedText.join(' ');
      // eslint-disable-next-line vue/no-mutating-props
      this.data.value = selectedVal.join(', ');
    },
    onConfirm(value) {
      // this.value = value;
      const index = this.columns.indexOf(value)
      this.showVantPicker = false;
      this.$emit('change', this.data.options[index])
    },
    clickOverlay() {
      this.showVantPicker = false;
    }
  }
};

</script>

<style lang="scss" scoped>
  .screen-li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height:50px ;
    border-bottom:1px solid $color-E6;
    .screen-title{
      color: #23252E;
      font-size: $font-15;
    }
    .screen-value{
      display: flex;
      flex:1;
      align-items: center;
      overflow: hidden;
      line-height: 24px;
      .screen-val{
        flex: 1;
        color:$color-333;
        font-size:$font-15;
        text-align: right;
        @extend .uniline-ellipsis;
      }
      .arrows{
        margin:0 15px 0 10px;
        color: $color-CCC;
        font-size: 11px;
      }
    }
    /deep/.van-picker__cancel {
      color: #999;
    }
    /deep/.van-picker__toolbar {
      color: #333;
    }
    /deep/.van-picker__confirm {
      color: $color-theme;
    }
  }
  .screen-li:last-child{
    border: 0;
  }
</style>
