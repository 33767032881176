<template>
  <cube-cascade-picker
    ref="cascadePicker"
    title="请选择"
    :data="data"
    :selected-index="selectedIndex()"
    @select="select"
    @cancel="cancel"
  >
  </cube-cascade-picker>
</template>

<script>
const COMPONENT_NAME = 'date-picker';
const EVENT_SELECT = 'select';
const EVENT_CANCEL = 'cancel';

import { substrDate } from '@/lib/until';

export default {
  name: COMPONENT_NAME,
  props: {
    min: {
      type: Array,
      default() {
        return [2000, 2, 1];
      }
    },
    max: {
      type: Array,
      default() {
        return [2020, 2, 1];
      }
    },
    needdefault: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedIndexArr: []
    };
  },
  computed: {
    data() {
      // eslint-disable-next-line no-use-before-define
      const data = range(this.min[0], this.max[0], false, '年');

      data.forEach((year) => {
        const minMonth = year.value === this.min[0] ? this.min[1] : 1;
        const maxMonth = year.value === this.max[0] ? this.max[1] : 12;

        // eslint-disable-next-line no-use-before-define
        year.children = range(minMonth, maxMonth, false, '月');
        year.children.forEach((month) => {
          let day = 30;
          if ([1, 3, 5, 7, 8, 10, 12].indexOf(month.value) > -1) {
            day = 31;
          } else if (month.value === 2) {
            day = !(year.value % 400) || (!(year.value % 4) && year.value % 100) ? 29 : 28;
          }

          const minDay = year.value === this.min[0] && month.value === this.min[1] ? this.min[2] : 1;
          const maxDay = year.value === this.max[0] && month.value === this.max[1] ? this.max[2] : day;
          // eslint-disable-next-line no-use-before-define
          month.children = range(minDay, maxDay, false, '日');
        });
      });

      return data;
    }
  },
  methods: {
    show() {
      this.$refs.cascadePicker.show();
    },
    hide() {
      this.$refs.cascadePicker.hide();
    },
    select(selectedVal, selectedIndex, selectedText) {
      this.selectedIndexArr = selectedIndex;
      const date = `${selectedVal[0]}-${substrDate(selectedVal[1])}-${substrDate(selectedVal[2])}`;
      this.$emit(EVENT_SELECT, date);
    },
    cancel() {
      this.$emit(EVENT_CANCEL);
    },
    selectedIndex() {
      let currentTime = [];
      currentTime = [new Date().getFullYear(), Number(new Date().getMonth() + 1), new Date().getDate()];
      const selectedIndex = [];
      this.data.forEach((itemY, indexY) => {
        if (itemY.value === currentTime[0]) {
          selectedIndex[0] = indexY;
          itemY.children.forEach((itemM, indexM) => {
            if (itemM.value === currentTime[1]) {
              selectedIndex[1] = indexM;
            }
            itemM.children.forEach((itemD, indexD) => {
              if (itemD.value === currentTime[2]) {
                selectedIndex[2] = indexD;
              }
            });
          });
        };
      });
      return this.selectedIndexArr.length ? this.selectedIndexArr : this.needdefault ? selectedIndex : [80, 0, 0];
    }
  }
};

function range(n, m, polyfill = false, unit = '') {
  const arr = [];
  for (let i = n; i <= m; i++) {
    const value = (polyfill && i < 10 ? `0${i}` : i) + unit;
    arr.push({
      text: value,
      value: i
    });
  }
  return arr;
}
</script>
