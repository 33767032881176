<template>
  <li
    class="screen-li"
    @click="showPicker(data.dap)"
  >
    <div class="screen-title">
      {{ data.title }}
    </div>
    <div class="screen-value">
      <span class="screen-val">{{ data.name }}</span>
      <i class="icon-joyo arrows">&#xe66c;</i>
    </div>
    <date-picker
      ref="datePicker"
      :min="data.min"
      :max="data.max"
      @select="dateSelectHandler"
    />
  </li>
</template>

<script>
import datePicker from '_c/date-picker';

import { getWeekInterval } from '@/lib/until.js';

export default {
  components: {
    datePicker
  },
  props: {
    data: {
      type: Object
    },
    filterData: {
      type: Object
    },
    weekInterval: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showPicker() {
      this.$refs.datePicker.show();
    },
    dateSelectHandler(selectedVal) {
      if (this.weekInterval) {
        const startTimeStr = getWeekInterval(selectedVal).startTimeStr;
        const endTimeStr = getWeekInterval(selectedVal).endTimeStr;
        // eslint-disable-next-line vue/no-mutating-props
        this.data.value = startTimeStr;
        // eslint-disable-next-line vue/no-mutating-props
        this.data.name = startTimeStr + '~' + endTimeStr;
        // eslint-disable-next-line vue/no-mutating-props
        this.filterData.endDate.value = this.filterData.endDate.name = endTimeStr;
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.data.value = this.data.name = selectedVal;
      }
    }
  }
};

</script>

<style lang="scss" scoped>
  .screen-li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height:50px ;
    border-bottom:1px solid $color-E6;;
    .screen-title{
      color: #23252E;
      font-size: $font-15;
    }
    .screen-value{
      .screen-val{
        flex: 1;
        color:$color-333;
        font-size:$font-15;
      }
      .arrows{
        margin:0 15px 0 10px;
        color: $color-CCC;
        font-size: 11px;
      }
    }
  }
  .screen-li:last-child{
    border: 0;
  }
</style>
